<template>
  <div class="loginBox flex_c_c">
    <div v-if="!token">
      <div class="logionCard">
        <div class="text_center titile">智弈达数智博物馆管理系统</div>
        <el-input class="loginIpt" v-model="inputForm.mobilePhone" placeholder="请输入登录账号"
                  prefix-icon="el-icon-user"
                  size="mini">
        </el-input>
        <el-input class="loginIpt maTops" v-model="inputForm.password" placeholder="请输入密码" size="mini"
                  :type="passwordShow ? 'password' : 'text'" prefix-icon="el-icon-lock"
                  auto-complete="new-password" @keyup.enter.native="login()">
          <template slot="suffix">
            <div v-show="passwordShow" @click="passwordShow = !passwordShow"
                 class="iconfont icon-eye-close passwordShowIcon"></div>
            <div v-show="!passwordShow" @click="passwordShow = !passwordShow"
                 class="iconfont icon-browse passwordShowIcon"></div>
          </template>
        </el-input>
        <div class="remmberBotton maTops">
          <el-checkbox class="rembenr" v-model="rememberPwd">记住密码</el-checkbox>
        </div>
        <div class="promptTop">
          <div class="prompt" v-show="numberFailures">
            {{ `第${numberFailures}次登录错误。连续5次登录错误，系统将锁定账号1小时，1小时后才能再次登录。` }}
          </div>
          <div class="loginBtn" @click="login">登录</div>
        </div>
      </div>
    </div>
    <div v-else>获取用户权限中，请稍后~</div>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";

export default {
  data() {
    return {
      token: this.QueryString('token') || '',
      id: this.QueryString('id') || '',
      inputForm: {
        mobilePhone: '',
        password: '',
        type: 1,
        xposition: 1
      },
      rememberPwd: false,
      rules: {
        mobilePhone: [
          {required: true, message: '帐号不能为空', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '密码不能为空', trigger: 'blur'}
        ],
      },
      numberFailures: 0,
      passwordShow: true,
    }
  },
  mounted() {
    if (this.token) {
      sessionStorage.setItem('token', this.token)
      document.cookie = `satoken=${this.token}`
      this.getUserInfo();
    }
    this.getCookie();
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      this.$axios(`${this.api.auth.GetUserById}/${this.id}`, {}, 'get').then(data => {
        if (data.status && data.data) {
          this.$store.commit('user/updateUser', data.data)
          this.getMenus();
        }
      })
    },
    // 获取用户权限
    getMenus() {
      this.$axios(this.api.auth.getMenus, {}, 'get').then(data => {
        console.log(data);
        if (data.status) {
          sessionStorage.setItem('allMenuList', JSON.stringify(data.data.menuList || []))
          sessionStorage.setItem('permissions', JSON.stringify(data.data.permissions || []))
          this.$router.push({name: 'menuHome'})
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    // 登录
    login() {
      if (this.inputForm.mobilePhone == '' || this.inputForm.password == '') {
        this.$message.error('请输入账号或密码');
        return false
      }
      if (this.rememberPwd) {
        this.setCookie(this.inputForm.mobilePhone, this.inputForm.password, 7);
      } else {
        //清空Cookie
        this.clearCookie();
      }
      this.$axios(this.api.auth.login, this.inputForm, 'post').then(data => {
        if (data.status) {
          sessionStorage.setItem('token', data.data.token)
          document.cookie = `satoken=${data.data.token}`
          this.$store.commit('user/updateUser', data.data)
          this.socketApi.initWebSocket()
          this.getMenus();
        } else {
          this.numberFailures = data.data + 1
          this.$message({
            message: data.msg,
          });
        }
      })
    },
    //储存cookie
    setCookie(name, pwd, exdays) {
      var exdate = new Date();
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      // Encrypt，加密账号密码
      var cipherName = CryptoJS.AES.encrypt(name + "", "secretkey123").toString();
      var cipherPwd = CryptoJS.AES.encrypt(pwd + "", "secretkey123").toString();
      document.cookie = "xxUserName=" + cipherName + ";path=/;expires=" + exdate.toGMTString()
      document.cookie = "xxUserPwd=" + cipherPwd + ";path=/;expires=" + exdate.toGMTString()
    },
    //获取cookie
    getCookie: function () {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split('; ');
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split('=');
          if (arr2[0] == 'xxUserName') {
            var bytes = CryptoJS.AES.decrypt(arr2[1], "secretkey123");
            this.inputForm.mobilePhone = bytes.toString(CryptoJS.enc.Utf8);
          } else if (arr2[0] == 'xxUserPwd') {
            var bytes = CryptoJS.AES.decrypt(arr2[1], "secretkey123");
            this.inputForm.password = bytes.toString(CryptoJS.enc.Utf8);
            if (arr2[1] != "") {
              this.rememberPwd = true;
            }
          }

        }
      }
    },
    //删除cookie
    clearCookie: function () {
      this.setCookie("", "", -1);
    },
  }
}
</script>
<style scoped lang="scss">
.loginBox {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: url('../../../../assets/img/logonBack.png') no-repeat;
  background-size: 100% 100%;

  .logionCard {
    position: absolute;
    right: 10rem;
    top: 50%;
    transform: translateY(-50%);
    width: 36.88rem;
    padding: 5rem 4rem;
    background: #FFFFFF;
    border-radius: 1.25rem;

    .titile {
      font-size: 1.88rem;
      font-weight: 500;
      padding-bottom: 5rem;
    }

    .maTops {
      margin-top: 2.5rem;
    }

    .loginIpt {
      display: flex;

      &::v-deep .el-input__inner {
        padding: 0 3rem 0 3rem;
        height: 3.63rem;
        font-size: 1.13rem;
        border: none;
        border-radius: 0;
        border-bottom: 0.15rem dashed #D7D8D9;
        background: #FFFFFF;

        &::placeholder {
          font-size: 1.13rem;
          color: #909399;
        }
      }

      &::v-deep .el-input__prefix {
        left: .2rem;
        font-size: 1.13rem;

        .el-input__icon {
          height: 3.63rem;
          line-height: 3.63rem;
          width: 2rem;
        }
      }

      &::v-deep .el-input__suffix {
        right: .2rem;
        height: 3.63rem;

        .el-input__suffix-inner {
          .passwordShowIcon {
            height: 3.63rem;
            font-size: 1.13rem;
            line-height: 3.63rem;
          }
        }
      }

      &::v-deep .el-input__suffix {
        display: flex;
        align-items: center;
      }
    }

    .remmberBotton {
      .rembenr {
        &::v-deep .el-checkbox__input {
          .el-checkbox__inner {
            width: 1rem;
            height: 1rem;
            border-radius: 0.15rem;
            border: .12rem solid #909399;
            &::after{
              top: -0.05rem !important;
              left: 0.26rem !important;
              width: 0.25rem !important;
              height: .6rem !important;
              border: none;
              border-bottom: .15rem solid #FFFFFF;
              border-right: .15rem solid #FFFFFF;
            }
          }
        }

        &::v-deep .el-checkbox__label{
          font-size: 1rem;
          line-height: 1rem;
          padding-left: .8rem;
        }

        /* 设置选中后的文字颜色 */
        &::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
          color: #E8A739;
        }

        /* 设置选中后对勾框的边框和背景颜色 */
        &::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .myRedCheckBox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          border-color: #E8A739;
          background-color: #E8A739;
        }
      }
    }

    .loginBtn {
      width: 100%;
      height: 3.63rem;
      flex: 1;
      line-height: 3.63rem;
      text-align: center;
      background: #E8A739;
      border-radius: 0.31rem;
      font-size: 1.25rem;
      font-weight: Medium;
      color: #FFFFFF;
      cursor: pointer;
    }

    .promptTop {
      margin-top: 4rem;

      .prompt {
        margin-bottom: .5rem;
        margin-right: -2.5rem;
        color: red;
        font-size: 0.9rem;
      }
    }
  }
}
</style>

